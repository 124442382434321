import { getAuthConfig } from './config';
import apiHelper from './index';

const uploadFile = async (
  payload,
  spinner = true,
  config = getAuthConfig()
) => {
  return await apiHelper.post('generic/file_upload/', payload, spinner, config);
};

const readFile = async (payload, spinner = true) => {
  return await apiHelper.post('generic/file_read/', payload, spinner);
};
const deleteFile = async (payload, spinner = true) => {
  return await apiHelper.post('generic/file_delete/', payload, spinner);
};

const uploadImage = async (payload, spinner = true) => {
  return await apiHelper.post(
    'generic/save_projector_image_to_s3/',
    payload,
    spinner
  );
};

const generatePresignedUrl = async (
  bucket_name,
  object_path,
  file = '',
  spinner = true
) => {
  const payload = {
    bucket_name,
    object_path,
    file,
  };
  return await apiHelper.post('generic/generate_new_url/', payload, spinner);
};
const getPresignedPostURL = async (payload, spinner = true) => {
  return await apiHelper.post(
    'generic/generate_presigned_post/',
    payload,
    spinner
  );
};

const verifyGetPresignedUrl = async (bucket_name, files, spinner = false) => {
  const payload = {
    bucket_name,
    files,
  };
  return await apiHelper.post(
    'generic/verify_generate_new_url/',
    payload,
    spinner
  );
};

export default {
  uploadFile,
  readFile,
  deleteFile,
  uploadImage,
  generatePresignedUrl,
  getPresignedPostURL,
  verifyGetPresignedUrl,
};
