import apiHelper from './index';

async function getObjects(params, spinner = false) {
  const { task, page = 1, pageSize, ordering } = params;
  return await apiHelper.get(
    `organization/task/fetch_all_annotation_image_list/${task}`,
    spinner
  );
}

async function getProjectorObject(params, spinner = false) {
  const { task, page = 1 } = params;
  return await apiHelper.get(
    `organization/task/projector_annotation_image_list/${task}/?page=${page}`,
    spinner
  );
}

async function saveCreatedRegions(payload, spinner = false) {
  return await apiHelper.post(
    'organization/task/task_object/',
    payload,
    spinner
  );
}

async function getProjectorRegionCoordinates(params, spinner = false) {
  const { id } = params;
  return await apiHelper.get(`generic/list_region_json/${id}/`, spinner);
}

async function updateRegions(payload, spinner = false) {
  return await apiHelper.patch(
    'organization/task/task_object/update_delete',
    payload,
    spinner
  );
}

async function updateProjectorRegions(payload, spinner = false) {
  return await apiHelper.put(
    'generic/update_projector_region/',
    payload,
    spinner
  );
}

async function updateProjectorRegionsJson(payload, id, spinner = false) {
  return await apiHelper.patch(
    `generic/list_region_json/${id}/`,
    payload,
    spinner
  );
}

async function deleteRegions(params, spinner = false) {
  return await apiHelper.remove(
    `organization/task/task_object/update_delete`,
    spinner,
    params
  );
}

async function copyRegions(payload, spinner = false) {
  return await apiHelper.patch(
    'generic/copy_projector_media/',
    payload,
    spinner
  );
}

async function setTaskStaticImage(payload, spinner = false) {
  return await apiHelper.post(
    'organization/annotation_image/get_static_image/',
    payload,
    spinner
  );
}

async function bulkDeleteAnnotations(payload, spinner = false) {
  return await apiHelper.patch(
    'organization/annotations/bulk-delete/',
    payload,
    spinner
  );
}

export default {
  getObjects,
  getProjectorObject,
  saveCreatedRegions,
  getProjectorRegionCoordinates,
  updateProjectorRegions,
  updateProjectorRegionsJson,
  updateRegions,
  deleteRegions,
  copyRegions,
  setTaskStaticImage,
  bulkDeleteAnnotations,
};
