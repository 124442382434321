import ApiHelper from './index';
import { getConfig, getAuthConfig } from './config';

const config = getConfig();
const authConfig = getAuthConfig();

async function signIn(payload) {
  return await ApiHelper.post('account/login_v2', payload, true, config);
}

async function signUp(payload) {
  return await ApiHelper.post(
    'account/register',
    payload,
    true,
    getAuthConfig()
  );
}

async function resendOtp() {
  return await ApiHelper.get('account/resend_otp/', true, getAuthConfig());
}

async function resendVerificationEmail(payload) {
  return await ApiHelper.post(
    'account/resend-verification-email',
    payload,
    true,
    config
  );
}

async function sendVerificationEmailAdmin(payload) {
  return await ApiHelper.post(
    'account/admin-verification-email',
    payload,
    true,
    getAuthConfig()
  );
}

async function verifyEmail(token, spinner = true) {
  return await ApiHelper.patch(
    `account/verify-email/${token}`,
    _,
    spinner,
    config
  );
}

async function setOTPMethod(payload) {
  return await ApiHelper.patch(
    `account/otp_receive_mode/`,
    payload,
    true,
    getAuthConfig()
  );
}

async function sendOTP() {
  return await ApiHelper.get(`account/send_otp_code/`, true, getAuthConfig());
}

async function verifyOTP(payload) {
  return await ApiHelper.post(
    `account/verify_otp_code/`,
    payload,
    true,
    getAuthConfig()
  );
}
async function getPermissions() {
  return await ApiHelper.get(
    'account/get-permissions/',
    _,
    true,
    getAuthConfig()
  );
}

async function acceptPolicy(email, payload) {
  return await ApiHelper.patch(
    `account/update-delete/${email}/`,
    payload,
    false,
    config
  );
}
async function azureSignIn(payload) {
  return await ApiHelper.post('account/azure-signin/', payload, true, config);
}

async function azureRefreshToken(payload) {
  return await ApiHelper.post(
    'account/refresh-azure-token/',
    payload,
    true,
    getAuthConfig()
  );
}

async function refreshJWTToken(payload) {
  return await ApiHelper.post(
    'account/token/refresh/',
    payload,
    true,
    getAuthConfig()
  );
}
async function azureSignUp(payload) {
  return await ApiHelper.post('account/azure-signup/', payload, true, config);
}

async function logout() {
  return await ApiHelper.patch('account/logout/', _, true, getAuthConfig());
}

async function forgotPassword(payload) {
  return await ApiHelper.patch(
    `account/forgot_password/`,
    payload,
    false,
    config
  );
}

async function changePassword(payload) {
  return await ApiHelper.patch(
    `account/reset_password/`,
    payload,
    false,
    config
  );
}

async function RequestChangePassword(payload) {
  return await ApiHelper.patch(
    `account/request_change_password/`,
    payload,
    false,
    getAuthConfig()
  );
}

async function RequestChangeEmail(payload) {
  return await ApiHelper.post(
    `account/request_change_email/`,
    payload,
    false,
    getAuthConfig()
  );
}

async function VerifyEmailChange(payload) {
  return await ApiHelper.patch(
    `account/verify-email-change/`,
    payload,
    false,
    getAuthConfig()
  );
}

async function UpdateWorkStationUserPassword(payload) {
  return await ApiHelper.patch(
    `account/reset_workstation_user_password/`,
    payload,
    false,
    getAuthConfig()
  );
}
async function AddOrgAdmin(payload) {
  return await ApiHelper.post(
    `account/register`,
    payload,
    false,
    getAuthConfig()
  );
}

async function RegisterAWSUser(payload) {
  try {
    const [error, res] = await ApiHelper.post(
      'account/register_for_aws_subscription',
      payload,
      true,
      getConfig()
    );

    if (error) {
      console.log('Full error response:', error);
      const errorData = error.response?.data?.error || 'An unknown error occurred';
      console.log('Error in RegisterAWSUser:', errorData);
      return [errorData];
    }
    console.log('Full response:', res);
    const data = res.response; 
    console.log('Data:', data);

    return [null, data]; 
  } catch (error) {
    console.log('Full error object:', error);
    const errorData = error.response?.data?.error || 'An unknown error occurred';
    console.log('Error in RegisterAWSUser:', errorData);
    return [errorData];
  }
}


async function RegisterNewAWSMarketplaceUser(payload){
  try {
    const hostURL = "https://h0jzksr8x6.execute-api.us-east-1.amazonaws.com/Prod/"
    // payload.route = '/firebase_topic_subscription';
    const { data } = await axios.post(
      `${hostUrl}subscriber`,
      payload,
      {
        headers: {
          'Content-Type': 'application\\json'
          // "Access-Control-Allow-Origin" : "*",
          // "Accept": "*/*",
          // "Connection" : "keep-alive",
          // "Access-Control-Allow-Methods": "GET, POST, DELETE, PUT"
        }
      }    
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}


export default {
  signIn,
  signUp,
  acceptPolicy,
  resendVerificationEmail,
  sendVerificationEmailAdmin,
  resendOtp,
  verifyEmail,
  setOTPMethod,
  verifyOTP,
  logout,
  RequestChangePassword,
  forgotPassword,
  changePassword,
  RequestChangeEmail,
  VerifyEmailChange,
  sendOTP,
  getPermissions,
  UpdateWorkStationUserPassword,
  azureSignIn,
  azureRefreshToken,
  azureSignUp,
  AddOrgAdmin,
  refreshJWTToken,
  RegisterAWSUser,
  RegisterNewAWSMarketplaceUser
};
