import DeviceService from 'src/services/device';
import {
  getDeviceDisplayNameToSerialNumMap,
  getDeviceIdToNameMap,
  getDeviceIdToSerialNumMap,
  getDeviceSerialNumToDisplayNameMap,
} from 'src/utils/device';
import types from '../mutation-types';

const state = {
  selectedTaskForDetail: {},
  taskCropParams: {},
  devices: [],
  activeDevices: [],
  isFetchingDevices: false,
  deviceSerialNumToDisplayNameMap: {},
  deviceDisplayNameToSerialNumMap: {},
  deviceIdToNameMap: {},
  deviceIdToSerialNumMap: {},
  cachedMonitoringStatuses: [],
  allDevices: [],
  selectedDeviceTasks: [],
};

const getters = {
  selectedTaskForDetail: (state) => state.selectedTaskForDetail,
  taskCropParams: (state) => state.taskCropParams,
  devices: (state) => state.devices,
  cachedActiveDevices: (state) => state.activeDevices,
  cachedMonitoringStatuses: (state) => state.cachedMonitoringStatuses,
  isFetchingDevices: (state) => state.isFetchingDevices,
  deviceSerialNumToDisplayNameMap: (state) =>
    state.deviceSerialNumToDisplayNameMap,
  deviceDisplayNameToSerialNumMap: (state) =>
    state.deviceDisplayNameToSerialNumMap,
  deviceIdToNameMap: (state) => state.deviceIdToNameMap,
  deviceIdToSerialNumMap: (state) => state.deviceIdToSerialNumMap,
  allDevices: (state) => state.allDevices,
  selectedDeviceTasks: (state) => state.selectedDeviceTasks,
};

const actions = {
  async setActiveDevices({ commit }) {
    const [error, data] = await DeviceService.getActiveDeviceStatus();
    if (error) {
      console.log(error);
      return;
    }
    commit(types.SET_ACTIVE_DEVICES, data);
    return [error, data];
  },

  async setMonitoringStatus({ commit }) {
    const [error, data] = await DeviceService.getMonitoringStatus();
    if (error) {
      console.log(error);
      return;
    }
    commit(types.SET_MONITORING_STATUS, data);
    return [error, data];
  },
  setDeviceForDetail({ commit }, payload) {
    commit(types.SET_DEVICE_FOR_DETAIL, payload);
  },

  setTaskCropParams({ commit }, payload) {
    commit(types.SET_TASK_CROP_PARAMS, payload);
  },

  resetDeviceState({ commit }) {
    commit(types.RESET_DEVICE_STATE);
  },
  
  async getTaskDevices({ commit },taskId){
    let [error, data] = await DeviceService.getTaskDevices(taskId)
    if(error){
      console.log(error)
      return;
    }
    commit(types.SET_DEVICE_TASKS, data);
  },

  async getOrgDevices({ commit }, orgName) {
    commit(types.SET_IS_FETCHING_DEVICES, true);
    const [error, data] = await DeviceService.fetchAllDevicesOfOrg(
      orgName,
      false
    );
    commit(types.SET_DEVICES_DATA, data);
    commit(types.SET_IS_FETCHING_DEVICES, false);
    if (error) {
      console.log({ error });
      return;
    }
    const devices = data.filter(
      (device) => device.Type !== 'departmental device'
    );
    commit(types.SET_DEVICES, devices);
    commit(
      types.SET_DEVICE_SERIAL_NUM_TO_DISPLAY_NAME_MAP,
      getDeviceSerialNumToDisplayNameMap(devices)
    );
    commit(
      types.SET_DISPLAY_NAME_TO_DEVICE_SERIAL_NUM_MAP,
      getDeviceDisplayNameToSerialNumMap(devices)
    );
    commit(types.SET_DEVICE_ID_TO_NAME_MAP, getDeviceIdToNameMap(devices));
    commit(
      types.SET_DEVICE_ID_TO_DEVICE_SERIAL_NUM_MAP,
      getDeviceIdToSerialNumMap(devices)
    );
  },
};

const mutations = {
  [types.SET_DEVICES_DATA](state, payload) {
    state.allDevices = payload;
  },
  [types.RESET_DEVICE_STATE](state) {
    (state.selectedTaskForDetail = {}),
      (state.taskCropParams = {}),
      (state.devices = []),
      (state.activeDevices = []),
      (state.isFetchingDevices = false),
      (state.deviceSerialNumToDisplayNameMap = {}),
      (state.deviceDisplayNameToSerialNumMap = {}),
      (state.deviceIdToNameMap = {}),
      (state.deviceIdToSerialNumMap = {}),
      (state.cachedMonitoringStatuses = []),
      (state.allDevices = []);
  },
  [types.SET_MONITORING_STATUS](state, payload) {
    state.cachedMonitoringStatuses = payload;
  },
  [types.SET_DEVICE_FOR_DETAIL](state, payload) {
    state.selectedTaskForDetail = payload;
  },
  [types.SET_DEVICES_DATA](state, payload) {
    state.allDevices = payload;
  },
  [types.SET_TASK_CROP_PARAMS](state, payload) {
    state.taskCropParams = payload;
  },
  [types.SET_DEVICE_TASKS](state, payload) {
    state.selectedDeviceTasks = payload;
  },
  [types.SET_DEVICES](state, payload) {
    state.devices = payload;
  },
  [types.SET_ACTIVE_DEVICES](state, payload) {
    state.activeDevices = payload;
  },
  [types.SET_IS_FETCHING_DEVICES](state, payload) {
    state.isFetchingDevices = payload;
  },
  [types.SET_DEVICE_SERIAL_NUM_TO_DISPLAY_NAME_MAP](state, payload) {
    state.deviceSerialNumToDisplayNameMap = payload;
  },
  [types.SET_DISPLAY_NAME_TO_DEVICE_SERIAL_NUM_MAP](state, payload) {
    state.deviceDisplayNameToSerialNumMap = payload;
  },
  [types.SET_DEVICE_ID_TO_NAME_MAP](state, payload) {
    state.deviceIdToNameMap = payload;
  },
  [types.SET_DEVICE_ID_TO_DEVICE_SERIAL_NUM_MAP](state, payload) {
    state.deviceIdToSerialNumMap = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
