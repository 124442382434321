import ApiHelper from './index';

const createModelType = async (payload, spinner = false) => {
  return await ApiHelper.post('organization/model_types', payload, spinner);
};

const fetchModelTypes = async (spinner = true) => {
  return await ApiHelper.get('organization/model_types', spinner);
};

const updateModelType = async (modelTypeId, payload, spinner = false) => {
  return await ApiHelper.patch(
    `organization/model_type/${modelTypeId}`,
    payload,
    spinner
  );
};

const createModelFiles = async (payload, spinner = false) => {
  return await ApiHelper.post('organization/model_files', payload, spinner);
};

const updateModelFiles = async (payload, spinner = false) => {
  return await ApiHelper.patch('organization/model_files', payload, spinner);
};

const deleteModelFIles = async (spinner = false, queryParam = {}) => {
  return await ApiHelper.remove(
    'organization/model_files',
    spinner,
    queryParam
  );
};

export default {
  createModelType,
  fetchModelTypes,
  updateModelType,
  createModelFiles,
  updateModelFiles,
  deleteModelFIles,
};
