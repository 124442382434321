import { createApp } from 'vue';
import mitt from 'mitt';
import store from './store/index';
import Toast from 'vue-toastification';
import toastOption from './config/toast-config';
import App from './App.vue';
import router from './router/index';
import Vue3VideoPlayer from '@cloudgeek/vue3-video-player';
import Vue3Tour from 'vue3-tour';
import VueTourSetup from './plugins/vue-tour-setup';
import Antd from 'ant-design-vue';
import VueFlatPickr from 'vue-flatpickr-component';
import CustomIcon from './components/shared/Components/CustomIcon.vue';
import clickOutside from './directives/clickOutside';

import 'flatpickr/dist/flatpickr.css';
import '@cloudgeek/vue3-video-player/dist/vue3-video-player.css';
import 'vue3-tour/dist/vue3-tour.css';
import 'vue3-circle-progress/dist/circle-progress.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'ant-design-vue/dist/antd.css';
import VueKonva from 'vue3-konva';
import contextmenu from 'v-contextmenu';
import 'v-contextmenu/dist/themes/default.css';
import customIcons from './config/custom-icons-config';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import VOtpInput from 'vue3-otp-input';
import firebaseMessaging from './config/firebase-config';

// Highcharts
import Highcharts from 'highcharts';
import SeriesLabelModule from 'highcharts/modules/series-label';
import HighchartsMore from 'highcharts/highcharts-more';
import ExportingModule from 'highcharts/modules/exporting';
import ExportDataModule from 'highcharts/modules/export-data';
import AccessibilityModule from 'highcharts/modules/accessibility';
import BoostModule from 'highcharts/modules/boost';
import HighchartsVue from 'highcharts-vue';
import XrangeModule from 'highcharts/modules/xrange.js';
import TimelineChartConfig from './components/user-panel/pages/analytics/Charts/HighCharts';

SeriesLabelModule(Highcharts);
HighchartsMore(Highcharts);
ExportingModule(Highcharts);
ExportDataModule(Highcharts);
AccessibilityModule(Highcharts);
BoostModule(Highcharts);
XrangeModule(TimelineChartConfig);

const app = createApp(App);

export const emitter = mitt();
const globalOptions = {
  mode: 'auto',
};
app.config.globalProperties.emitter = emitter;
app.config.globalProperties.$customIcons = customIcons;
app.config.globalProperties.$messaging = firebaseMessaging;

app.component('v-otp-input', VOtpInput);
app.use(VueTelInput, globalOptions);
app.use(store);
app.use(Toast, toastOption);
app.use(Vue3VideoPlayer);
app.use(Vue3Tour);
app.use(VueTourSetup);
app.use(Antd);
app.use(VueKonva);
app.use(router);
app.use(contextmenu);
app.use(VueFlatPickr);
app.provide('emitter', emitter);
// app.use(Markdown);
app.component('CustomIcon', CustomIcon);
app.directive('click-outside', clickOutside);
app.use(HighchartsVue);
app.mount('#app');
